import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import contractAbi from "./abi.json";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
export const StyledButton = styled.button`
font-family: "Courier New", Courier, monospace;
  cursor: pointer;
  width: auto;
  height: 5%;
  border: solid 3px #4bd187;
  box-shadow: 0 0 5px #4bd187, 0 0 15px #4bd187, 0 0 25px #4bd187;
  animation: animate 5s linear infinite;
  background: #0f0f0f13;
  backdrop-filter: blur(2px);
  color: #767579;
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  transition: 0.5s; 
  display: flex;
  border-radius: 5%;
  padding: 2rem;

:hover {
  color: #ffffff;
}
`;
export const StyledConnectButton = styled.button`
font-family: "Courier New", Courier, monospace;
position: absolute;
top: 0%;
right: 0%;
cursor: pointer;
background: rgba(250, 250, 250, 0.7);
text-align: center;
text-transform: uppercase;
transition: 0.5s;           
border: none; 
display: block;
font-size: 2vw;
color: black;

:hover {
transform: scale(1.05);
background: black;

}
`;

export const StyledRoundButton = styled.button`
background: transparent;
  border: none;
  color: black;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  font-size: 2vw;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-radius: 50%;
  :hover{
    opacity: 0.9;
    transform: scale(1.2);
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
color: #2ca5ff;
text-decoration: none;
:hover{
  opacity: 0.9;
  color: #2ca5ff;
}
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`1 per address`);
  const [mintAmount, setMintAmount] = useState(1);
  const [accountMintedLimit, setAccountMinted] = useState(0);
  const [tokenID, setTokenID] = useState(0);
  const [imageData, setImageData] = useState(null);
  const [whitelisted, setwhitelisted] = useState(false);
  const [numberMinted, setnumberMinted] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [dataFetched, updateFetched] = useState(false);
  const [paused, setPaused] = useState(true);
  const ethers = require("ethers");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    TWITTER_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getWhitelist = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .whitelistMintEnabled()
    .call();
    setwhitelisted(res);
    let pub = await blockchain.smartContract.methods
    .publicMintEnabled()
    .call();
    setPublic(pub);}
  };

  const getAccountMinted = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .minted(blockchain.account)
    .call();
    setAccountMinted(res);
  }};
  
  const getPaused = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .saleIsActive()
    .call();
    setPaused(res);}
  };

const getMinted = async () => {
  if (blockchain.smartContract !== null){
  let res = await blockchain.smartContract.methods
  .numMinted()
  .call();
  console.log
  setTotalAmount(res);
}};

const getTokenImage = async (number) => {
  let res = await blockchain.smartContract.methods.tokenURI(number).call();
  const base64Metadata = res.split("data:application/json;base64,")[1];
  let metadataStr = atob(base64Metadata);
  
  const metadata = await JSON.parse(metadataStr);
  console.log(metadata.image);
  setImageData(metadata.image);
};
  const ClaimNFT =  () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let a = accountMintedLimit;
    if (paused == false) { gasLimit = 1300000000;setFeedback("Contract is Paused");return;}
    if (Number(a) == 1){setFeedback(`This address has minted ${a}`);return;} 
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit + mintAmount * 2800);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Creating Transaction`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)//, proof
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something Went Wrong");
        setClaimingNft(false);
      })
      .then((receipt) => {
        //console.log(receipt.events.Transfer.returnValues.tokenId);//data:application/json;base64,
        setTokenID(receipt.events.Transfer.returnValues.tokenId);
        getTokenImage(receipt.events.Transfer.returnValues.tokenId);
        setFeedback(
          `You minted token #${receipt.events.Transfer.returnValues.tokenId}`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
      
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      console.log(tokenID);
        
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
   
  }, [blockchain.account]);

  const getTotal = async () => {
     const ethers = require("ethers");
     const url = process.env.REACT_APP_ALCHEMY_HTTPS;
     const jsonprovider = new ethers.providers.JsonRpcProvider(url);
     let contract = new ethers.Contract("0x1af1099C39C593F583956CcA7769C4cfF0F9AcEa", contractAbi, jsonprovider)
     let total = await contract.numMinted();
     setTotalAmount(total);//total
     updateFetched(true);
     };
 
   if(!dataFetched){
     getTotal();

   }

  setTimeout(() => {
    getPaused();
    getAccountMinted();
    getMinted();
  }, 1000);

  
  return (
    
      <s.Container
        flex={1}
        ai={"center"} jc={"center"} style={{
          
        }}
        
      >
        
        {Number(data.totalSupply) >= (CONFIG.MAX_SUPPLY)? (
              <>
                <s.Container
                  style={{ 
                    background:"transparent",
                    position: "absolute",
                    width: "50%",
                    height: "auto",
                    right:"0%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "1",
                         }}>
                
                  
                <s.TextTitle
                  style={{zIndex:1,margin:"5%", textAlign: "center",
                  fontFamily: "'Courier New', Courier, monospace",
                  fontWeight:"bolder",
                  color: "#767579",position:"absolute", 
                  background:"transparent",
                  top:"0%",
                   }}
                >
                  We sold out, thank you!
                </s.TextTitle>
                
                <s.SpacerMedium />

                <s.SpacerMedium />
                </s.Container>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  
                  <s.Container ai={"center"} jc={"center"}fd={"column"} >
                   <s.SpacerMedium /><s.SpacerMedium />
                    <div
                    style={{
                      fontWeight: "bold",
                      zIndex:1}}
                      id="connect-btn"
                    className="container-gif"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                   Connect
                    </div>

                    <s.TextDescription
                          style={{
                            
                            zIndex:1,
                            fontFamily: "Courier New, Courier, monospace",
                            textAlign: "center",
                            color: "#767579",
                            padding:"1rem",
                            fontSize:"3vmin",
                            position:"absolute",
                            bottom:"30vh",
                            right: "max(19vw, 15%)",
                            
                          }}
                        > Total Minted <br/>
                          {Number(totalamount)} / {CONFIG.MAX_SUPPLY}
                        </s.TextDescription>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        
                        <s.TextDescription
                          style={{
                            zIndex:1,
                            textAlign: "center",
                            color: "black",
                            position:"absolute",
                            right: "5%"
                            
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                    
                  </s.Container>
                ) : ( 
                  
                  < >
                  <s.Container
                  className="mintPage"
                  style={{
                    position: "absolute",
                    width: "50%",
                    height: "50%",
                    border: "solid 3px #767579",
                    background: "#0f0f0f13",
                    backdropFilter: "blur(2px)",
                    borderRadius: "0.4rem",
                    display: "flex",
                    top: "0",
                    pointerEvents: "all",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "1",
                         }}>   
                  <s.SpacerMedium />
                  <s.SpacerMedium />
                  <s.TextTitle
              style={{
                margin: "5%",
                textAlign: "center",
                fontSize: "3vw",
                zIndex:1,
                color: "#767579",
                
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>

                    <StyledButton
                      style={{
                        fontSize: "max(2vw, 15px)",
                        fontWeight: "bold",
                        zIndex:1}}
                        onClick={(e) => {
                          e.preventDefault(); 
                          ClaimNFT();
                          getData();
                        }}
                      >
                        
                        {claimingNft ? "Minting" : "Mint"}
                      </StyledButton> 
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
            <s.SpacerSmall />
            
                      </s.Container>
                      
                <s.SpacerLarge />
                <s.TextDescription
                      style={{
                        zIndex:1,
                        fontSize: "1.5vw",
                        padding:"15px",
                        maxWidth:"90%",
                        color: "#ffffff",
                        
                      }}
                    >
                    {feedback}
                    </s.TextDescription>
                    {Number(tokenID) == (0)?null:(<>
                    <img src={imageData} alt="Token" 
                     style={{
                      position: "absolute",
                      width: "50%",
                      height: "50%",
                      border: "solid 3px #767579",
                      borderRadius: "0.4rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: "1",
                           }}/></>)}
                <s.SpacerLarge />
                      </s.Container>
                  </>
                )}
                
              </>
            )}

      </s.Container>
  );
  
}
export default App;

